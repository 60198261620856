import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "alert",
      "style": {
        "position": "relative"
      }
    }}>{`Alert`}</h1>
    <p>{`The alerts appear on the top of the screen and they display critical information about loss of data, functionality, error, etc.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Avoid displaying more than one alert at a time and long messages.`}</li>
      <li parentName="ul">{`Use notifications to present information that needs to be viewed immediately and that requires user attention.`}</li>
      <li parentName="ul">{`Present clear, concise messages and give follow up actions to allow the user to become more informed or resolve the issue.`}</li>
      <li parentName="ul">{`Avoid displaying messages with technical error codes that do not help the user solve the problem.`}</li>
      <li parentName="ul">{`Present specific messages to each situation instead of using standard messages.`}</li>
      <li parentName="ul">{`Take the blame when it's appropriate. Say "we're having trouble connecting" rather than "you're having connection issues".`}</li>
    </ul>
    <h2 {...{
      "id": "alert-types",
      "style": {
        "position": "relative"
      }
    }}>{`Alert types`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Information</th>
      <td>
        Blue color. The default notification is usually used to give information that doesn't require too much attention
        or action. They are typically not in response to a user action. (E.g.: "Logged in as João da Silva. Logout.")
      </td>
    </tr>
    <tr>
      <th>Danger</th>
      <td>
        Red color. Shows to the user that some process or task cannot be completed. Error messages should be clear and
        objective, avoiding presenting technical error codes. Whenever possible should present a solution or link
        indicating the next step. (E.g.: "The last item could not be added. Please refresh the page.")
      </td>
    </tr>
    <tr>
      <th>Warning</th>
      <td>
        Orange color. They are usually in anticipation of a significant change. Clearly communicate what will happen if
        the user proceed with the action and provide an alternative where possible. (E.g.: "Your license is about to
        expire. Please renew your license by August 5, 2019.")
      </td>
    </tr>
    <tr>
      <th>Success</th>
      <td>Green color. Provides a positive response to user actions. (E.g.: "Professional added successfully.")</td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}>{`Examples`}</h2>
    <Demo src='pages/components/alert/AlertDefault' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      